import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'total1'
})
export class Total1Pipe implements PipeTransform {

  transform(items: any[], filter = "", filter1 = "",filter2 = ""): any {
    var total=0
    if(!items) return 0;

    filter = filter.toLowerCase();
    console.log(items)
    for(let a of items.filter(item => item.nom_four.toLowerCase().indexOf(filter) !== -1 && item.date_bon.indexOf(filter1) !== -1 && item.n_bon_cmd.indexOf(filter2) !== -1))
    {
      console.log(a.n_bon_cmd)
      total = total + (a.total*1)
    }
    return total
    }

}
