import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'charge'
})
export class ChargePipe implements PipeTransform {

  transform(items: any[], filter = "", filter1 = ""): any {
    if(!items) return [];
    return items.filter(
      item =>  item.libelle.indexOf(filter) !== -1 && item.date.indexOf(filter1) !== -1);
      
    }

}
