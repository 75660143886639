import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalcharge'
})
export class TotalchargePipe implements PipeTransform {

  transform(items: any[], filter = "", filter1 = ""): any {
    var total=0
    if(!items) return [];
    
    for(let a of items.filter(item =>  item.libelle.indexOf(filter) !== -1 && item.date.indexOf(filter1) !== -1))
    {
      total = total + (a.montant*1)
    }
    return total
    }

}
