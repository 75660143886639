import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http : HttpClient, private route: Router) { }

  login(username: string, password: string) {
    return this.http.post<any>(this.getPath()+"/login.php/", { username: username, password: password });
  }
  getUser() {
    return this.http.post<any>(this.getPath()+"/getUser.php/", { id_user: this.getToken()});
  }
  getClient() {
    return this.http.post<any>(this.getPath()+"/getClient.php/", {id_user: this.getToken()});
  }
  getOneClient(id: String) {
    return this.http.post<any>(this.getPath()+"/getOneClient.php/", {id_client: id});
  }
  deleteClient(id: String) {
    return this.http.post<any>(this.getPath()+"/deleteClient.php/", {id: id});
  }
  addClient(nom: String,adr: String,cp: String,ville: String,tel: String) {
    return this.http.post<any>(this.getPath()+"/addClient.php/", {nom: nom,adr: adr,cp: cp,ville: ville,tel: tel});
  }
  modClient(id_client: String, nom: String,adr: String,cp: String,ville: String,tel: String) {
    return this.http.post<any>(this.getPath()+"/modClient.php/", {id_client: id_client, nom: nom,adr: adr,cp: cp,ville: ville,tel: tel});
  }
  getFour() {
    return this.http.post<any>(this.getPath()+"/getFour.php/", {id_user: this.getToken()});
  }
  getOneFour(id: String) {
    return this.http.post<any>(this.getPath()+"/getOneFour.php/", {id_four: id});
  }
  deleteFour(id: String) {
    return this.http.post<any>(this.getPath()+"/deleteFour.php/", {id: id});
  }
  addFour(nom: String,adr: String,cp: String,ville: String,tel: String) {
    return this.http.post<any>(this.getPath()+"/addFour.php/", {nom: nom,adr: adr,cp: cp,ville: ville,tel: tel});
  }
  modFour(id_four: String, nom: String,adr: String,cp: String,ville: String,tel: String) {
    return this.http.post<any>(this.getPath()+"/modFour.php/", {id_four: id_four, nom: nom,adr: adr,cp: cp,ville: ville,tel: tel});
  }
  getArticle() {
    return this.http.post<any>(this.getPath()+"/getArticle.php/", {});
  }
  addBoni(idcli: String, pay: string,credit:number) {
    return this.http.post<any>(this.getPath()+"/addBoni.php/", {idcli: idcli,pay:pay,credit:credit});
  }
  addBon1i(idcli: String,depence: number,depencevalue: number,pay: string,credit: number) {
    return this.http.post<any>(this.getPath()+"/addBon1i.php/", {idcli: idcli,depence:depence,depencevalue:depencevalue, pay:pay, credit: credit});
  }
  getProduit() {
    return this.http.post<any>(this.getPath()+"/getProduct.php/", {});
  }
  addBon(idcli: String, ref: String, libelle: String,quantity: String,prix: String,bini: String) {
    return this.http.post<any>(this.getPath()+"/addBon.php/", {idcli: idcli, ref: ref, libelle: libelle,quantity: quantity, prix: prix,bini: bini});
  }
  addBon1(idcli: String, ref: String, libelle: String,quantity: String,prix: String,bini: String) {
    return this.http.post<any>(this.getPath()+"/addBon1.php/", {idcli: idcli, ref: ref, libelle: libelle,quantity: quantity, prix: prix,bini: bini});
  }
  getBon() {
    return this.http.post<any>(this.getPath()+"/getBon.php/", {});
  }
  getBon1() {
    return this.http.post<any>(this.getPath()+"/getBon1.php/", {});
  }
  getOneBon(id:String,type:number){
    return this.http.post<any>(this.getPath()+"/getOneBon.php/", {id: id, type: type});
  }
  getStatistic(){
    return this.http.post<any>(this.getPath()+"/statistic.php/", {});
  }
  deleteArticle(id:number){
    return this.http.post<any>(this.getPath()+"/deleteArticle.php/", {id:id});
  }
  deleteArticle1(id:number){
    return this.http.post<any>(this.getPath()+"/deleteArticle1.php/", {id:id});
  }
  editArticle(quantity:number, prix:String,id_article:number){
    return this.http.post<any>(this.getPath()+"/editArticle.php/", {quantity:quantity, prix:prix, id_article:id_article});
  }
  valideBon(id:number){
    return this.http.post<any>(this.getPath()+"/valideBon.php/", {id:id});
  }
  deleteBon(id:number){
    return this.http.post<any>(this.getPath()+"/deleteBon.php/", {id:id});
  }
  getCharge(){
    return this.http.post<any>(this.getPath()+"/getCharge.php/", {});
  }
  addCharge(libelle:String, montant:String){
    return this.http.post<any>(this.getPath()+"/addCharge.php/", {libelle:libelle, montant:montant});
  }
  deleteCharge(id:number){
    return this.http.post<any>(this.getPath()+"/deleteCharge.php/", {id:id});
  }
  getArt() {
    return this.http.post<any>(this.getPath()+"/getArt.php/", {});
  }
  modifierArticle(id: number,article:string) {
    return this.http.post<any>(this.getPath()+"/modifierArticle.php/", {id:id,article:article});
  }
  modifierArticleprix(id: String,prix:number) {
    console.log(id,prix)
    return this.http.post<any>(this.getPath()+"/modifierArticleprix.php/", {id:id,prix:prix});
  }
  addProduit(lib:string){
    return this.http.post<any>(this.getPath()+"/addProduit.php/", {lib:lib});
  }
  creditId(id:string,type:number){
    return this.http.post<any>(this.getPath()+"/creditId.php/", {id:id,type:type});
  }
  deleteCredit(id:string){
    return this.http.post<any>(this.getPath()+"/deleteCredit.php/", {id:id});
  }
  ajouetrCredit(montant: number,id:number){
    return this.http.post<any>(this.getPath()+"/ajouterCredit.php/", {montant:montant,id:id});
  }
  getTotalCredit(){
    return this.http.post<any>(this.getPath()+"/getTotalCredit.php/", {});
  }
  addRetour(id,a0,a2,a3,datecharge,dateretour){
    return this.http.post<any>(this.getPath()+"/addRetour.php/", {id:id,a0:a0,a2:a2,a3:a3,datecharge:datecharge,dateretour:dateretour});
  }
  getRetour(){
    return this.http.post<any>(this.getPath()+"/getRetour.php/", {});
  }
  deleteRetour(id: number){
    return this.http.post<any>(this.getPath()+"/deleteRetour.php/", {id: id});
  }
  sendToken(token: string) {
    localStorage.setItem("LoggedInUser", token)
  }
  getToken() {

    return localStorage.getItem("LoggedInUser")
  }
  isLoggednIn() {
    return this.getToken() !== null;
  }
  logout() {
    localStorage.removeItem("LoggedInUser");
    this.route.navigate(["login"]);
  }
    //https://shopi2019.000webhostapp.com/oberlo/
  //http://localhost/angular2/  http://bytaeo.com
path ="http://test.cheikha-hanna.com/sanod/";
getPath()
{
  return this.path;
}
}