import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'total'
})
export class TotalPipe implements PipeTransform {
  total=0
  transform(items: any[], filter="", filter1="", filter2=""): any {
    this.total=0
    if(!items) return 0;
    else
    { 
      for(let a of items)
      {
        this.total=this.total+(a.total*1)
      }
      return this.total
    }
    }
}
