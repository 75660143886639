import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-bon',
  templateUrl: './bon.component.html',
  styleUrls: ['./bon.component.css']
})
export class BonComponent implements OnInit {
  
    nom1=''
    cp1=''
    ville1=''
    tel1=''
    adr1=''
    mot1: String
    myVar=0
  
    constructor(private service: ServiceService, private elem: ElementRef,private route: ActivatedRoute, private router: Router) { }
  
    bon: any
    getBon()
    {
      this.service.getBon().subscribe(
        data => {   
          console.log(data)
          this.bon=data
          },
        error => { 
          console.log(error)
        });
    }
    bon1: any
    getBon1()
    {
      this.service.getBon1().subscribe(
        data => {   
          console.log(data)
          this.bon1=data
          },
        error => { 
          console.log(error)
        });
    }
  
    BonItem: any
    totalPrice=0
    totalQ=0
    getOneBon(id: String,type :number)
    {
      this.totalPrice=0
      this.totalQ=0
      this.service.getOneBon(id,type).subscribe(
        data => {   
          this.BonItem=data
          for(let a of data)
          {
            this.totalQ+=parseInt(a.quantity_article)
            this.totalPrice+=a.quantity_article*a.prix_article
          }
          console.log(this.BonItem)
          },
        error => { 
          console.log(error)
        });
    }
    
    deleteItem(id,bon)
    {
      this.service.deleteArticle1(id).subscribe(
        data => {  
          console.log(data) 
          this.getOneBon(bon,1)
          },
        error => {
          console.log(error) 
          this.getOneBon(bon,1)
          });
    }

    id_article:number
    id_bon1:number
    editItem(id_article,id_bon1)
    {
      this.id_article=id_article
      this.id_bon1=id_bon1
    }
    editarticle(quantity,prix,bon,id_article)
    {
      this.service.editArticle(quantity,prix,id_article).subscribe(
        data => {   
          this.getOneBon(bon,1)
          },
        error => { 
          this.getOneBon(bon,1)
          });
    }
    valideBon(id)
    {
      this.service.valideBon(id).subscribe(
        data => {   
          this.getBon()
          },
        error => { 
          this.getBon()
          });
    }

    deleteBon(id)
    {
      this.service.deleteBon(id).subscribe(
        data => {   
          this.getBon()
          },
        error => { 
          this.getBon()
          });
    }

    selectItem(id_Bon,type)
    {
      this.getOneBon(id_Bon,type)
    }     
    
    print()
    {
     let printContents = document.getElementById('div1').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
     
      setTimeout(function() {
                      location.reload();
                }, 1000);
    }

    print1()
    {
      let printContents = document.getElementById('div2').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
     setTimeout(function() {
      location.reload();
}, 1000);
    }

    print7()
    {
     let printContents = document.getElementById('div7').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
     
      setTimeout(function() {
                      location.reload();
                }, 1000);
    }

    date2=""
    date1=""

    creditid:any
  creditId(id,type)
  {
    console.log("hhh")
    this.service.creditId(id,type).subscribe(
      data => {   
        console.log(data)
        this.creditid=data
        },
      error => { 
        console.log(error)
        });
  }
  deleteCredit(id,type,bon)
  {
    console.log(id,type)
    this.service.deleteCredit(id).subscribe(
      data => {   
        this.creditId(bon,type)
        },
      error => { 
        this.creditId(bon,type)
        });
  }
  boncre=0
  typecredit=0
  ajouterCredit(id,typecredit)
  {
    this.typecredit=typecredit
    this.boncre=id
  }
  ajouterMontantCredit(montant, id)
  {
    console.log(montant,id)
    this.service.ajouetrCredit(montant,id).subscribe(
      data => {   
        this.creditId(id,this.typecredit)
        },
      error => { 
        this.creditId(id,this.typecredit)
        });
  }

    ngOnInit() {
      if(this.route.snapshot.params['mot']!=0)
      this.mot1 = this.route.snapshot.params['mot'];
      console.log(this.mot1)
      this.getBon()
      this.getBon1()
     
    }
  
  }
  