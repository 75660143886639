import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pipe'
})
export class PipePipe implements PipeTransform {

  transform(items: any[], filter = "", filter1 = ""): any {
    if(!items) return [];
    return items.filter(
      item =>  item.handle.indexOf(filter) !== -1 && item.created_at.indexOf(filter1) !== -1);
      
    }
  }


