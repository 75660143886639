import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AccueilComponent } from './accueil/accueil.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { PipePipe } from './pipe.pipe';
import { LoginComponent } from './login/login.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ClientComponent } from './client/client.component';
import { FournisseurComponent } from './fournisseur/fournisseur.component';
import { ArticleComponent } from './article/article.component';
import { BonComponent } from './bon/bon.component';
import { FourPipe } from './four.pipe';
import { ClientPipe } from './client.pipe';
import { ArticlePipe } from './article.pipe';
import { BonPipe } from './bon.pipe';
import { DepenceComponent } from './depence/depence.component';
import { Bon1Pipe } from './bon1.pipe';
import { ArtnameComponent } from './artname/artname.component';
import { Article1Component } from './article1/article1.component';
import { Bon1Component } from './bon1/bon1.component';
import { ChargePipe } from './charge.pipe';
import { TotalPipe } from './total.pipe';
import { Total1Pipe } from './total1.pipe';
import { TotalchargePipe } from './totalcharge.pipe';
import { RetourComponent } from './retour/retour.component';
import { AfficheRetourComponent } from './affiche-retour/affiche-retour.component';
import { RetourPipe } from './retour.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AccueilComponent,
    PipePipe,
    LoginComponent,
    SafeHtmlPipe,
    ClientComponent,
    FournisseurComponent,
    ArticleComponent,
    BonComponent,
    FourPipe,
    ClientPipe,
    ArticlePipe,
    BonPipe,
    DepenceComponent,
    Bon1Pipe,
    ArtnameComponent,
    Article1Component,
    Bon1Component,
    ChargePipe,
    TotalPipe,
    Total1Pipe,
    TotalchargePipe,
    RetourComponent,
    AfficheRetourComponent,
    RetourPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HttpModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
