import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  error = ''
  user: any

  constructor(private formBuilder: FormBuilder, private service: ServiceService, private router: Router) { }
  get f() { return this.loginForm.controls; }
  
  onSubmit() {
    console.log("kjhkjhkjh")
    this.submitted = true;
    
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
console.log(this.f.username.value, this.f.password.value)
    this.service.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          this.service.sendToken(data.val_test)
          this.router.navigate(["/accueil"]);
        },
        error => {
          this.error="Password ou UserName Incorrect"
          this.loading = false;
        });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
    });
  }

}
