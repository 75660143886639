import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-retour',
  templateUrl: './retour.component.html',
  styleUrls: ['./retour.component.css']
})
export class RetourComponent implements OnInit {

  validetraitment=false
  idcli:any

  constructor(private service: ServiceService) { }

  client: any
  getClient()
  {
    this.service.getClient().subscribe(
      data => {   
        this.client=data
        },
      error => { 
        console.log(error)
      });
  }
  article: any

  getArticle()
  {
    this.service.getArticle().subscribe(
      data => {   
        this.article=data
        console.log(data)
        },
      error => {
        console.log(error)
      });
  }

  listlibellevalide=[]
  addlibList(libelle,quantity,quantity1,prix)
  {
    this.listlibellevalide.push([libelle,quantity,quantity1,prix])
    console.log(this.listlibellevalide)
  }

  bonList=[]
  alertStock=false
  alertStock1=false
  alertStock2=false
  alertStock5=false
  retourClient(datecharge,dateretour)
  {
    this.alertStock=false
    this.alertStock1=false
    this.alertStock2=false
    this.alertStock5=false
  
    for(let a of this.listlibellevalide)
    {
      if(a[2]=="")
      {
        this.alertStock2=true
        return
      }   
    }
    if(!this.idcli)
    {
      this.alertStock1=true
    }
    else if(!datecharge || !dateretour)
    {
      this.alertStock5=true
    }
    else
    {
      this.validetraitment=true
      for(let a of this.listlibellevalide)
      {
      this.addRetour(this.idcli,a[0],a[2],a[3],datecharge,dateretour)
      }
    }
  }

  addRetour(id: String,a0: String,a2: String,a3: String, datecharge: String,dateretour){
    this.service.addRetour(id,a0,a2,a3,datecharge,dateretour).subscribe(
      data => {   
        this.getArticle()
        console.log(data)
        },
      error => { 
        this.getArticle()
        console.log(error)
        });
  }

  resetData()
{
  this.listlibellevalide=[]
  this.validetraitment=false
}


  ngOnInit() {
    this.getClient()
    this.getArticle()
  }

}
