import { Component, ElementRef, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-fournisseur',
  templateUrl: './fournisseur.component.html',
  styleUrls: ['./fournisseur.component.css']
})
export class FournisseurComponent implements OnInit {

  nom1=''
  cp1=''
  ville1=''
  tel1=''
  adr1=''

  constructor(private service: ServiceService, private elem: ElementRef) { }

  four: any
  getFour()
  {
    this.service.getFour().subscribe(
      data => {   
        this.four=data
        },
      error => { 
        console.log(error)
      });
  }

  fourItem: any
  getOneFour(id: String)
  {
    this.service.getOneFour(id).subscribe(
      data => {   
        this.fourItem=data
        console.log(this.fourItem)
        this.nom1=data[0].nom_four
        this.adr1=data[0].adr_four
        this.cp1=data[0].cp_four
        this.ville1=data[0].ville_four
        this.tel1=data[0].tel_four
        },
      error => { 
        console.log(error)
      });
  }
  deleteFour(id : string)
  {
    this.service.deleteFour(id).subscribe(
      data => {   
        this.getFour()
        },
      error => { 
        this.getFour()
        });
  }
  modFour(id_four: String, nom: String,adr: String,cp: String,ville: String,tel: String){
    this.service.modFour(id_four,nom,adr,cp,ville,tel).subscribe(
      data => {   
        this.getFour()
        },
      error => { 
        this.getFour()
        });
  } 

  addFour(nom: String,adr: String,cp: String,ville: String,tel: String){
    console.log(nom,adr,cp,ville,tel)
    this.service.addFour(nom,adr,cp,ville,tel).subscribe(
      data => {   
        this.getFour()
        },
      error => { 
        this.getFour()
        });
  }

  selectItem(id_four)
  {
    this.getOneFour(id_four)
  }
  ngOnInit() {
    this.getFour()
  }

}
