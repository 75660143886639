import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor(private service: ServiceService, private router: Router) { }
  private currentDate = new Date();
  
  
      logout()
      {
        this.service.logout()
      }
      nom_user: String
      getUser(){
        this.service.getUser()
            .subscribe(
              data => {
                this.nom_user = data.val_test
                console.log(this.nom_user)
              },
              error => {
              });
      }
      
  ngOnInit() {
    if(this.service.isLoggednIn())
    {
      this.getUser()
    }
    else
    this.service.logout()
  }

}
