import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ServiceService } from './service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private router: Router, private formBuilder: FormBuilder, private service: ServiceService) { }
  
    ngOnInit() {
     }
}
