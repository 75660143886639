import { Component, ElementRef, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {

  clifou=0
  idcli:any
  idcli1:any
  libelle=""
  ididbon=0
  depencevalue=0
  credit=0
  validetraitment=false

  constructor(private service: ServiceService, private elem: ElementRef) { }

  article: any

  listlibellevalide=[]
  addlibList(libelle,quantity,quantity1,prix)
  {
    this.listlibellevalide.push([libelle,quantity,quantity1,prix])
    console.log(this.listlibellevalide)
  }

  resetData()
{
  this.listlibellevalide=[]
  this.validetraitment=false
}

  getArticle()
  {
    this.service.getArticle().subscribe(
      data => {   
        this.article=data
        console.log(data)
        },
      error => {
        console.log(error)
      });
  }

  clientItem: any
  getOneClient(id: String)
  {
    this.service.getOneClient(id).subscribe(
      data => {   
        this.clientItem=data
        console.log(this.clientItem)
        },
      error => { 
        console.log(error)
      });
  } 

  addClient(nom: String,adr: String,cp: String,ville: String,tel: String){
    this.service.addClient(nom,adr,cp,ville,tel).subscribe(
      data => {   
        this.getArticle()
        },
      error => { 
        this.getArticle()
        });
  }

  clickButton(btn)
  {
    this.depencevalue=0
    this.clifou=btn
    this.ididbon=0
    
    this.bonList=[]
  }

  bonList=[]
  alertStock=false
  alertStock1=false
  alertStock2=false
  bonClient(pay,credit)
  {
    this.alertStock=false
    this.alertStock1=false
    this.alertStock2=false
    this.alertStock5=false
  
    for(let a of this.listlibellevalide)
    {
      if(a[1]<a[2])
      {
        this.alertStock=true
        return
      }
      if(a[2]=="" || a[3]=="")
      {
        this.alertStock2=true
        return
      }   
    }
    if(!this.idcli)
    {
      this.alertStock1=true
    }
    else if(!pay)
    {
      this.alertStock5=true
    }
    else
    {
      this.service.addBoni(this.idcli,pay,credit).subscribe(
        data => {   
          this.id_bini=data.val_test
          console.log(data)
          this.validetraitment=true
          for(let a of this.listlibellevalide)
          {
          this.addBon(this.idcli,'',a[0],a[2],a[3],this.id_bini)
          }
          },
        error => { 
          console.log(error)
        });
    }
  }
  
  alertStock3=false
  alertStock4=false
  alertStock5=false
  bonFournisseur(ref1,libelle1,quantity1,quantity11,prix1,depence,depencevalue,pay)
  {
    console.log(depence,depencevalue)
    this.alertStock3=false
    this.alertStock4=false
    this.alertStock2=false
    this.alertStock5=false
    console.log(quantity1,quantity11,prix1)
    if(!this.idcli1)
    {
      this.alertStock3=true
    }
    else if(!quantity11 || quantity11=="" || !prix1 || prix1=="")
    {
      this.alertStock2=true
    }
    else if(!depence)
    {
      this.alertStock4=true
    }
    else if(!pay)
    {
      this.alertStock5=true
    }
    else
    {
      this.ididbon=this.ididbon+1
      if(this.ididbon==1)
    {
      this.service.addBon1i(this.idcli1,depence,depencevalue,pay,0).subscribe(
        data => {   
          this.id_bini=data.val_test
          console.log(data)
          this.addBon1(this.idcli1,ref1,libelle1,quantity11,prix1,this.id_bini)
          this.bonList.push([libelle1,quantity11,prix1,quantity11*prix1])
          },
        error => { 
          console.log(error)
        });
    }
    else{
    this.addBon1(this.idcli1,ref1,libelle1,quantity11,prix1,this.id_bini)
    this.bonList.push([libelle1,quantity11,prix1,quantity11*prix1])
    }
  }
  }

  addBon(idcli: String,ref: String,libelle: String,quantity: String, prix: String,bini){
    this.service.addBon(idcli,ref,libelle,quantity,prix,bini).subscribe(
      data => {   
        this.getArticle()
        console.log(data)
        },
      error => { 
        this.getArticle()
        console.log(error)
        });
  }
  addBon1(idcli: String,ref: String,libelle: String,quantity: String, prix: String,bini){
    this.service.addBon1(idcli,ref,libelle,quantity,prix,bini).subscribe(
      data => {   
        this.getArticle()
        console.log(data)
        },
      error => { 
        this.getArticle()
        console.log(error)
        });
  }
  client: any
  getClient()
  {
    this.service.getClient().subscribe(
      data => {   
        this.client=data
        },
      error => { 
        console.log(error)
      });
  }
  four: any
  getFour()
  {
    this.service.getFour().subscribe(
      data => {   
        this.four=data
        },
      error => { 
        console.log(error)
      });
  }

  id_bini: any
 

  addlibadd(Lib)
  {
    this.service.addBon(null,null,Lib,'0','0','0').subscribe(
      data => {   
        this.getArticle()
        console.log(data)
        },
      error => { 
        this.getArticle()
        console.log(error)
        });
  }
  ngOnInit() {
    this.getArticle()
    this.getClient()
    this.getFour()
  }

}
