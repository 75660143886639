import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

  constructor(private service: ServiceService, private router: Router) { }

  statistic=[]
  getStatistic()
  {
    this.service.getStatistic().subscribe(
      data => {   
        this.statistic=data
        console.log(data)
        },
      error => { 
        console.log(error)
        });
  }
  getCredit:any
  getTotalCredit()
  {
    this.service.getTotalCredit().subscribe(
      data => {   
        this.getCredit=data
        console.log(data)
        },
      error => { 
        console.log(error)
        });
  }
  ngOnInit() {
    if(this.service.getToken()!='1')
    {
      this.router.navigate(["/article"]);
    }
    this.getStatistic()
    this.getTotalCredit()
  }

}
