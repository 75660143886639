import { Component, ElementRef, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-artname',
  templateUrl: './artname.component.html',
  styleUrls: ['./artname.component.css']
})
export class ArtnameComponent implements OnInit {

  constructor(private service: ServiceService, private elem: ElementRef) { }

  Article: any
  getArticle()
  {
    this.service.getArt().subscribe(
      data => {   
        this.Article=data
        },
      error => { 
        console.log(error)
      });
  }

  deleteArticle(id : number)
  {
    console.log(id)
    this.service.deleteArticle(id).subscribe(
      data => {   
        this.getArticle()
        },
      error => { 
        this.getArticle()
        });
  }
  modifierArticle(id : number,article:string)
  {
    console.log(id)
    this.service.modifierArticle(id,article).subscribe(
      data => {   
        this.getArticle()
        },
      error => { 
        this.getArticle()
        });
  }

  modifierart1=0
  modifierart(id)
  {
    this.modifierart1=id
    console.log(this.modifierart1)
  }
  addArticle(libelle: String){
    this.service.addBon(null,null,libelle,'0','0','0').subscribe(
      data => {   
        this.getArticle()
        console.log(data)
        },
      error => { 
        this.getArticle()
        console.log(error)
        });
  }

  
  ngOnInit() {
    this.getArticle()
  }

}
