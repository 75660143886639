import { Component, ElementRef, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  nom1=''
  cp1=''
  ville1=''
  tel1=''
  adr1=''

  constructor(private service: ServiceService, private elem: ElementRef) { }

  client: any
  getClient()
  {
    this.service.getClient().subscribe(
      data => {   
        this.client=data
        },
      error => { 
        console.log(error)
      });
  }

  clientItem: any
  getOneClient(id: String)
  {
    this.service.getOneClient(id).subscribe(
      data => {   
        this.clientItem=data
        console.log(this.clientItem)
        this.nom1=data[0].nom_client
        this.adr1=data[0].adr_client
        this.cp1=data[0].cp_client
        this.ville1=data[0].ville_client
        this.tel1=data[0].tel_client
        },
      error => { 
        console.log(error)
      });
  }
  deleteClient(id : string)
  {
    this.service.deleteClient(id).subscribe(
      data => {   
        this.getClient()
        },
      error => { 
        this.getClient()
        });
  }
  modClient(id_client: String, nom: String,adr: String,cp: String,ville: String,tel: String){
console.log(id_client,nom,adr,cp,ville,tel)
    
    this.service.modClient(id_client,nom,adr,cp,ville,tel).subscribe(
      data => {   
        this.getClient()
        },
      error => { 
        this.getClient()
        });
  } 

  addClient(nom: String,adr: String,cp: String,ville: String,tel: String){
    this.service.addClient(nom,adr,cp,ville,tel).subscribe(
      data => {   
        this.getClient()
        },
      error => { 
        this.getClient()
        });
  }

  selectItem(id_client)
  {
    this.getOneClient(id_client)
  }
  ngOnInit() {
    this.getClient()
  }

}
