import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bon1'
})
export class Bon1Pipe implements PipeTransform {

  list=[]
  list1=[]
  transform(items: any[], filter="", filter1="", filter2=""): any {
    if(!items) return [];

        filter = filter.toLowerCase();
        return items.filter(item => item.nom_four.toLowerCase().indexOf(filter) !== -1 && item.date_bon.indexOf(filter1) !== -1 && item.n_bon_cmd.indexOf(filter2) !== -1);
  }
}
