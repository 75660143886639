import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'four'
})
export class FourPipe implements PipeTransform {

  transform(items: any[], filter: String): any {
    if(!items) return [];

    if(!filter){
      console.log(filter)
       return items;
      }   
      else if (filter)
      {

        filter = filter.toLowerCase();
        return items.filter(item => item.nom_four.toLowerCase().indexOf(filter) !== -1);
      }
  }

}
