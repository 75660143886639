import { Component, ElementRef, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-depence',
  templateUrl: './depence.component.html',
  styleUrls: ['./depence.component.css']
})
export class DepenceComponent implements OnInit {

  constructor(private service: ServiceService, private elem: ElementRef) { }

  charge: any
  getCharge()
  {
    this.service.getCharge().subscribe(
      data => {   
        this.charge=data
        },
      error => { 
        console.log(error)
      });
  }

  deleteCharge(id : number)
  {
    this.service.deleteCharge(id).subscribe(
      data => {   
        this.getCharge()
        },
      error => { 
        this.getCharge()
        });
  }

  addCharge(libelle: String,montant: String){
    this.service.addCharge(libelle,montant).subscribe(
      data => {   
        this.getCharge()
        },
      error => { 
        this.getCharge()
        });
  }

  print7()
    {
     let printContents = document.getElementById('div7').innerHTML;
     let originalContents = document.body.innerHTML;
     document.body.innerHTML = printContents;
     window.print();
     document.body.innerHTML = originalContents;
     
      setTimeout(function() {
                      location.reload();
                }, 1000);
    }
  
  ngOnInit() {
    this.getCharge()
  }

}
