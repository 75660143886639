import { Component, ElementRef, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-article1',
  templateUrl: './article1.component.html',
  styleUrls: ['./article1.component.css']
})
export class Article1Component implements OnInit {

  clifou=0
  idcli:any
  idcli1:any
  libelle=""
  depencevalue=0
  credit=0
  article1=""
  constructor(private service: ServiceService, private elem: ElementRef) { }

  trans=1

listlibellevalide=[]
  addlibList(libelle)
  {
    this.listlibellevalide.push([libelle])
    console.log(this.listlibellevalide)
  }
  article: any

  getProduit()
  {
    this.service.getProduit().subscribe(
      data => {   
        this.article=data
        console.log(data)
        },
      error => {
        console.log(error)
      });
  }

  
  clickButton(btn)
  {
    this.depencevalue=0
    this.clifou=btn
    
  }

  alertStock=false
  alertStock1=false
  alertStock2=false
  
  alertStock3=false
  alertStock4=false
  alertStock5=false

  taille:number
  a11:number
  a33:number
  validetraitment=false
  bonFournisseur(depence,pay,depencevalue,credit)
  {
    this.alertStock3=false
    this.alertStock4=false
    this.alertStock2=false
    this.alertStock5=false
    if(!this.idcli1)
    {
      this.alertStock3=true
    }
    else if(!depence)
    {
      this.alertStock4=true
    }
    else if(!pay)
    {
      this.alertStock5=true
    }
    else
    {
     
      this.service.addBon1i(this.idcli1,depence,depencevalue,pay,credit).subscribe(
        data => {   
          this.id_bini=data.val_test
          console.log(data)
          this.validetraitment=true
          for(let a of this.listlibellevalide)
          {
            this.a11=parseFloat(a[2])
            this.a33=parseFloat(a[3])
            depencevalue=parseFloat(depencevalue)
            
            console.log(Number(Number(((depencevalue/this.trans)+this.a11+this.a33))))
            console.log(Number(((depencevalue/this.trans)+this.a11+this.a33).toFixed(2)))
            this.modifierArticlePrix(a[0],Number(((depencevalue/this.trans)+this.a11+this.a33).toFixed(2)))
          this.addBon1(this.idcli1,'',a[0],a[1],a[2],this.id_bini)
          }
          
          },
        error => { 
      console.log(error)
    });    
  }
}

resetData()
{
  this.listlibellevalide=[]
  this.validetraitment=false
}

  addBon1(idcli: String,ref: String,libelle: String,quantity: String, prix: String,bini){
    this.service.addBon1(idcli,ref,libelle,quantity,prix,bini).subscribe(
      data => {   
        this.getProduit()
        console.log(data)
        },
      error => { 
        this.getProduit()
        console.log(error)
        });
  }
  
  four: any
  getFour()
  {
    this.service.getFour().subscribe(
      data => {   
        this.four=data
        },
      error => { 
        console.log(error)
      });
  }

  id_bini: any
 

  addlibadd(Lib)
  {
    this.service.addProduit(Lib).subscribe(
      data => {   
        this.getProduit()
        console.log(data)
        },
      error => { 
        this.getProduit()
        console.log(error)
        });
  }

  modifierArticlePrix(id : String,prix:number)
  {
    console.log(id)
    this.service.modifierArticleprix(id,prix).subscribe(
      data => {  
        console.log(data) 
        this.getProduit()
        },
      error => { 
        console.log(error)
        this.getProduit()
        });
  }
  calculeTrans(){
    var q=0
    for(let a of this.listlibellevalide)
    {
      q=q+Number(a[1])
    }
    this.trans=q
    console.log(this.trans)
}
  modifierArticle(id : number,article:string)
  {
    console.log(id)
    this.service.modifierArticle(id,article).subscribe(
      data => {   
        console.log(data)
        this.getProduit()
        this.article1=""
        },
      error => { 
        console.log(error)
        this.getProduit()
        });
  }


  modifierart1=0
  modifierart(id,libelle)
  {
    this.modifierart1=id
    this.article1=libelle
    console.log(this.modifierart1)
  }

  deleteArticle(id : number)
  {
    console.log(id)
    this.service.deleteArticle(id).subscribe(
      data => {   
        this.getProduit()
        },
      error => { 
        this.getProduit()
        });
  }

  ngOnInit() {
    this.getProduit()
    this.getFour()
  }

}
