import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'retour'
})
export class RetourPipe implements PipeTransform {

  transform(items: any[], filter = "", filter1 = "", filter2 = "", filter3 = ""): any {
    if(!items) return [];
    filter = filter.toLowerCase();
    filter1 = filter1.toLowerCase();
    return items.filter(
      item =>  item.nom_client.indexOf(filter) !== -1 && item.libelle_article.indexOf(filter1) !== -1 && item.date_charge.indexOf(filter2) !== -1 && item.date_retour.indexOf(filter3) !== -1);
    }

}
